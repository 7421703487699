<template>
  <div>
    <a-row style="padding-bottom: 30px">
      Do you have a GTA license number?
    </a-row>
    <a-row>
      <a-col>
        <a-button @click.native="setHasGTA" type="primary"
          >Yes, proceed</a-button
        >

        <a-button target="_blank" :href="url" type="link"
          >No, create one</a-button
        >
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Persist from "@/mixins/Persist";
export default {
  mixins: [Persist],
  computed: {
    hasGTA() {
      return this.$getFromLocal("hasGTA");
    },
  },
  data() {
    return {
      url: "https://visitghana.com",
    };
  },
  mounted() {
    if (this.hasGTA) {
      this.$emit("next");
    }
  },
  methods: {
    setHasGTA() {
      this.$storeInLocal("hasGTA", true);
      this.$emit("next");
    },
  },
};
</script>
