<template>
  <a-spin :spinning="loading">
    <div>
      <a-steps :current="current">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps>

      <div class="mt-5">
        <levy-number-requirement-form
          @next="goToNext($event, current)"
          v-if="current === 0"
        />

        <basic-information-form
          @next="goToNext($event, current)"
          v-if="current === 1"
        />

        <business-information-form
          @next="goToNext($event, current)"
          v-if="current === 2"
        />

        <a-row v-if="current === steps.length - 1"> We all good to go! </a-row>
      </div>
      <div class="steps-action"></div>
    </div>
  </a-spin>
</template>

<script>
import BasicInformationForm from "@/components/onboarding/BasicInformationForm";
import BusinessInformationForm from "@/components/onboarding/BusinessInformationForm";
import LevyNumberRequirementForm from "@/components/onboarding/LevyNumberRequirementForm";

import { MISC_GET_COUNTRIES } from "@/store/actions";

import { mapActions } from "vuex";
import { merge } from "lodash";

import Profile from "@/mixins/Profile";

export default {
  mixins: [Profile],
  components: {
    LevyNumberRequirementForm,
    BasicInformationForm,
    BusinessInformationForm,
  },
  data() {
    return {
      loading: false,

      current: 0,
      steps: [
        {
          title: "Levy Number Requirement",
        },
        {
          title: "Basic Information",
        },
        {
          title: "Business Information",
        },
        {
          title: "Done",
        },
      ],
      data: {},
    };
  },
  computed: {},
  watch: {
    "account.has_completed_onboarding": {
      handler(completed) {
        if (completed) {
          return this.$router.push(
            `/${this.getUserAccountProfileModule}/dashboard`
          );
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getCountries();
  },
  methods: {
    ...mapActions("misc", {
      getCountries: MISC_GET_COUNTRIES,
    }),
    next() {
      if (this.steps.length - 1 !== this.current) this.current++;
    },
    prev() {
      if (this.current) this.current--;
    },
    async goToNext(data, current) {
      try {
        this.loading = true;

        if (data) {
          this.data = merge(this.data, data);
        }

        if (current === this.steps.length - 2) {
          await this.completeProfileOnboarding(this.data);

          this.next();
          setTimeout(async () => {
            this.$router.push(`/${this.getUserAccountProfileModule}/dashboard`);
          }, 1000);
        } else {
          this.next();
        }
      } catch (e) {
        this.$notification.error({
          message: "An Error Occurred",
          description:
            e.message ||
            "Please go over your details, make sure all fields are properly filled",
        });
        //
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
</style>
