<template>
  <a-spin :spinning="loading">
    <div>
      <div class="row">
        <div class="col-md-6">
          <a-form-item label="Country">
            <a-select
              @change="fetchCountryBanks"
              show-search
              option-filter-prop="country"
              :filter-option="filterOptions"
              :disabled="disabled"
              v-decorator="[
                'bank.country_code',
                {
                  rules: [{ required: true, message: 'Country is required!' }],
                },
              ]"
              placeholder="Please select your country"
            >
              <a-select-option
                :key="index"
                v-for="(countryObject, index) in countries"
                :value="countryObject.iso_code"
              >
                {{ countryObject.country }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>

        <div class="col-md-6">
          <a-form-item label="Bank">
            <a-select
              show-search
              :disabled="!getBanksForCountry.length || disabled"
              :filter-option="filterOptions"
              option-filter-prop="name"
              v-decorator="[
                'bank.bank_id',
                {
                  rules: [{ required: true, message: 'Bank is required!' }],
                },
              ]"
              placeholder="Please select your bank"
            >
              <a-select-option
                :key="index"
                v-for="(bankBranchObject, index) in getBanksForCountry"
                :value="bankBranchObject.id"
              >
                {{ bankBranchObject.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <a-form-item label="Bank Account Number">
            <a-input
              :disabled="disabled"
              placeholder="Please enter your bank account number"
              v-decorator="[
                'bank.account_number',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Business bank account number is required!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  MISC_GET_GEO_DATA,
  MISC_GET_BANKS,
  MISC_GET_BANKS_BRANCHES,
} from "@/store/actions";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("misc", [
      "getCountriesList",
      "getBanksForCountry",
      "getBankBranches",
    ]),
    countries() {
      return this.getCountriesList.filter((country) => country.supported);
    },
  },
  methods: {
    ...mapActions("misc", {
      getGeoData: MISC_GET_GEO_DATA,
      getBanksOfCountry: MISC_GET_BANKS,
      getBranchesOfBank: MISC_GET_BANKS_BRANCHES,
    }),
    filterOptions(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async fetchCountryBanks(countryIsoCode) {
      try {
        this.loading = true;
        await this.getBanksOfCountry(countryIsoCode);
      } catch (error) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
