<template>
  <div class="col-md-10">
    <a-form :form="form">
      <div class="row">
        <div class="col-md-6">
          <a-form-item label="Business Name">
            <a-input
              placeholder="Please enter your business name"
              v-decorator="[
                'name',
                {
                  rules: [
                    { required: true, message: 'Business Name is required!' },
                  ],
                },
              ]"
            />
          </a-form-item>
        </div>

        <div class="col-md-6">
          <a-form-item label="Business Email">
            <a-input
              placeholder="Please enter your business email"
              v-decorator="[
                'email',
                {
                  rules: [{ required: true, message: 'Email is required!' }],
                },
              ]"
            />
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <a-form-item label="Business Description">
            <a-textarea
              :autoSize="{ minRows: 10, maxRows: 50 }"
              placeholder="Please enter your  business description"
              v-decorator="[
                'description',
                {
                  rules: [
                    { required: true, message: 'Description is required!' },
                  ],
                },
              ]"
            />
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <facility-phone-number-form-item :showLabel="true" />
        </div>

        <div class="col-md-6">
          <a-form-item label="License number">
            <a-input
              placeholder="Please enter your License number"
              v-decorator="[
                'tourism_authority_number',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Business License number is required!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <facility-category-form-item />
        </div>

        <div class="col-md-6">
          <a-form-item label="Ghana Post GPS">
            <a-input
              placeholder="Please enter your Ghana Post GPS"
              v-decorator="[
                'ghana_post_gps_address',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Ghana Post GPS is required!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <a-form-item id="youtube_video_link" label="Youtube Video Link">
            <a-input
              size="large"
              v-decorator="[
                'youtube_video_link',
                {
                  rules: [],
                },
              ]"
              placeholder="https://youtube.come/zdgasdgaxxxadsg"
            />
          </a-form-item>
        </div>

        <div class="col-md-6">
          <a-form-item id="website-url" label="Website Url">
            <a-input
              size="large"
              v-decorator="[
                'website_url',
                {
                  rules: [],
                },
              ]"
              placeholder="https://www.visitghana.com"
            />
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <a-form-item label="General Policy" id="general-policy">
            <a-textarea
              placeholder="General Policy"
              :rows="10"
              v-decorator="[
                'general_policy',
                {
                  rules: [],
                },
              ]"
            />
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <a-form-item label="Refund Policy" id="refund-policy">
            <a-textarea
              placeholder="Refund Policy"
              :rows="10"
              v-decorator="[
                'refund_policy',
                {
                  rules: [],
                },
              ]"
            />
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <a-form-item label="Cancellation Policy" id="cancellation-policy">
            <a-textarea
              placeholder="Cancellation Policy"
              :rows="10"
              v-decorator="[
                'cancellation_policy',
                {
                  rules: [],
                },
              ]"
            />
          </a-form-item>
        </div>
      </div>

      <facility-address-form-item
        ref="facilityAddress"
        :isOnboarding="true"
        :showGoogle="true"
        @update-business-location="updateBusinessLocation"
      />

      <div class="row">
        <div class="col-md-6">
          <p
            class="text-success font-bold font-size-16"
            v-if="hasBusinessLocationProvided"
          >
            Business Location Provided
          </p>
          <p class="text-danger" v-else>Business Not Location Provided</p>
        </div>
      </div>

      <facility-bank-form-item ref="facilityBank" />

      <div class="row">
        <div class="col-md-6">
          <a-form-item>
            <a-button @click="handleSubmit" type="primary" html-type="submit">
              Next
            </a-button>
          </a-form-item>
        </div>
      </div>

      <a-row> </a-row>
    </a-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MISC_GET_GEO_DATA } from "@/store/actions";
import { cloneDeep } from "lodash";
import Persist from "@/mixins/Persist";

import FacilityPhoneNumberFormItem from "@/components/facilities/FacilityPhoneNumberFormItem";
import FacilityCategoryFormItem from "@/components/facilities/FacilityCategoryFormItem";
import FacilityAddressFormItem from "@/components/facilities/FacilityAddressFormItem";
import FacilityBankFormItem from "@/components/facilities/FacilityBankFormItem";

export default {
  components: {
    FacilityPhoneNumberFormItem,
    FacilityCategoryFormItem,
    FacilityAddressFormItem,
    FacilityBankFormItem,
  },
  mixins: [Persist],
  data() {
    return {
      form: this.$form.createForm(this, { name: "basicBusinessInformation" }),
      location: {},
    };
  },
  computed: {
    ...mapGetters("misc", [
      "getCountriesList",
      "archivedGeoData",
      "getGeoDataStates",
      "getGeoDataCities",
      "getBanksForCountry",
    ]),
    businessInformationFromStore() {
      return this.$getFromLocal("businessInformation");
    },
    hasBusinessLocationProvided() {
      return (
        this.getValueFromSource(this.location, "latitude") &&
        this.getValueFromSource(this.location, "longitude")
      );
    },
  },
  async mounted() {
    if (this.businessInformationFromStore) {
      try {
        this.form.setFieldsValue(this.businessInformationFromStore);

        const { address, bank } = this.businessInformationFromStore;
        if (address && address.state) {
          await this.getCountryStates(address.state);
        }

        if (address && address.city) {
          await this.getStateCities(address.state);
        }

        if (bank && bank.bank_id && bank.country_code) {
          await this.$refs.facilityBank.fetchCountryBanks(bank.country_code);
        }
      } catch (error) {
        //
      }
    }
  },
  methods: {
    ...mapActions("misc", {
      getGeoData: MISC_GET_GEO_DATA,
    }),
    updateBusinessLocation(data) {
      const { latitude, longitude } = data;
      console.log("update location", data);
      this.location = { latitude, longitude };
    },
    filterOptions(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    coerceAddressValue(countryStateCity) {
      const { cities, states } = this.archivedGeoData;
      try {
        const countryStructure = (address) => {
          return {
            geo_id: address.geo_name_id,
            latitude: address.latitude,
            longitude: address.longitude,
            name: address.country,
            iso_code: address.iso_code,
          };
        };
        const stateAndCityStructure = (address) => {
          return {
            geo_id: address.geonameId,
            latitude: address.lat,
            longitude: address.lng,
            name: address.name,
          };
        };
        const ghanaGeoNameId = 2300660;
        const data = this.getCountriesList.find(
          (country) => ghanaGeoNameId == country.geo_name_id
        );
        countryStateCity.country = countryStructure(data);

        if (countryStateCity.state) {
          const data = states.find(
            (state) => countryStateCity.state == state.geonameId
          );
          countryStateCity.state = stateAndCityStructure(data);
        }
        if (countryStateCity.city) {
          const data = cities.find(
            (city) => countryStateCity.city == city.geonameId
          );
          countryStateCity.city = stateAndCityStructure(data);
        }
        return countryStateCity;
      } catch (e) {
        console.log(e);
      }
    },
    coercePhone(number, prefix) {
      return {
        number,
        formatted:
          parseInt(number[0]) === 0 ? `${prefix}${number.slice(1)}` : number,
      };
    },
    completeBankData(bankObject) {
      if (bankObject.bank_id) {
        return {
          ...bankObject,
          //will always exist because we got it from it
          bank_name: this.getBanksForCountry.find(
            (bank) => bank.id === bankObject.bank_id
          ).name,
        };
      }
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (values) {
          this.$storeInLocal("businessInformation", values);
        }

        if (!err) {
          try {
            if (!this.location.latitude || !this.location.latitude) {
              throw new Error("Please enter and select your location.");
            }

            const modifiedValues = cloneDeep(values);
            const { address } = modifiedValues;

            modifiedValues["address"] = this.coerceAddressValue(address);
            modifiedValues.phones = this.coercePhone(
              modifiedValues.phones,
              modifiedValues.prefixPhone
            );

            modifiedValues.phones = [modifiedValues.phones];
            modifiedValues.location = this.location;
            modifiedValues.bank = this.completeBankData(modifiedValues.bank);

            const { bank, prefixPhone, ...data } = modifiedValues;
            this.$emit("next", { manager: data, bank });

            delete values.bank;

            this.$storeInLocal("businessInformation", values);
          } catch (error) {
            this.$notification.error({
              message: "An Error Occurred",
              description: error.message,
            });
          }
        }
      });
    },
    async getCountryStates(geoId) {
      await this.getGeoData({ geoId, type: "states" });
    },
    async getStateCities(geoId) {
      await this.getGeoData({ geoId, type: "cities" });
    },
  },
};
</script>
