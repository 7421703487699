var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-10"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":"Business Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [
                  { required: true, message: 'Business Name is required!' },
                ],
              },
            ]),expression:"[\n              'name',\n              {\n                rules: [\n                  { required: true, message: 'Business Name is required!' },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Please enter your business name"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":"Business Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                rules: [{ required: true, message: 'Email is required!' }],
              },
            ]),expression:"[\n              'email',\n              {\n                rules: [{ required: true, message: 'Email is required!' }],\n              },\n            ]"}],attrs:{"placeholder":"Please enter your business email"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Business Description"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'description',
              {
                rules: [
                  { required: true, message: 'Description is required!' },
                ],
              },
            ]),expression:"[\n              'description',\n              {\n                rules: [\n                  { required: true, message: 'Description is required!' },\n                ],\n              },\n            ]"}],attrs:{"autoSize":{ minRows: 10, maxRows: 50 },"placeholder":"Please enter your  business description"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('facility-phone-number-form-item',{attrs:{"showLabel":true}})],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":"License number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'tourism_authority_number',
              {
                rules: [
                  {
                    required: true,
                    message: 'Business License number is required!',
                  },
                ],
              },
            ]),expression:"[\n              'tourism_authority_number',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Business License number is required!',\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Please enter your License number"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('facility-category-form-item')],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":"Ghana Post GPS"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'ghana_post_gps_address',
              {
                rules: [
                  {
                    required: true,
                    message: 'Ghana Post GPS is required!',
                  },
                ],
              },
            ]),expression:"[\n              'ghana_post_gps_address',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Ghana Post GPS is required!',\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Please enter your Ghana Post GPS"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"id":"youtube_video_link","label":"Youtube Video Link"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'youtube_video_link',
              {
                rules: [],
              },
            ]),expression:"[\n              'youtube_video_link',\n              {\n                rules: [],\n              },\n            ]"}],attrs:{"size":"large","placeholder":"https://youtube.come/zdgasdgaxxxadsg"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"id":"website-url","label":"Website Url"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'website_url',
              {
                rules: [],
              },
            ]),expression:"[\n              'website_url',\n              {\n                rules: [],\n              },\n            ]"}],attrs:{"size":"large","placeholder":"https://www.visitghana.com"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"General Policy","id":"general-policy"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'general_policy',
              {
                rules: [],
              },
            ]),expression:"[\n              'general_policy',\n              {\n                rules: [],\n              },\n            ]"}],attrs:{"placeholder":"General Policy","rows":10}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Refund Policy","id":"refund-policy"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'refund_policy',
              {
                rules: [],
              },
            ]),expression:"[\n              'refund_policy',\n              {\n                rules: [],\n              },\n            ]"}],attrs:{"placeholder":"Refund Policy","rows":10}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Cancellation Policy","id":"cancellation-policy"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'cancellation_policy',
              {
                rules: [],
              },
            ]),expression:"[\n              'cancellation_policy',\n              {\n                rules: [],\n              },\n            ]"}],attrs:{"placeholder":"Cancellation Policy","rows":10}})],1)],1)]),_c('facility-address-form-item',{ref:"facilityAddress",attrs:{"isOnboarding":true,"showGoogle":true},on:{"update-business-location":_vm.updateBusinessLocation}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[(_vm.hasBusinessLocationProvided)?_c('p',{staticClass:"text-success font-bold font-size-16"},[_vm._v(" Business Location Provided ")]):_c('p',{staticClass:"text-danger"},[_vm._v("Business Not Location Provided")])])]),_c('facility-bank-form-item',{ref:"facilityBank"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v(" Next ")])],1)],1)]),_c('a-row')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }