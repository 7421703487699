<template>
  <div>
    <a-form :form="form">
      <div class="row">
        <div class="col-md-6">
          <a-form-item label="First Name">
            <a-input
              pattern="[A-Za-z]\w+"
              placeholder="Please enter your first name"
              v-decorator="[
                'first_name',
                {
                  rules: [
                    { required: true, message: 'First Name is required!' },
                  ],
                },
              ]"
            />
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <a-form-item label="Last Name">
            <a-input
              pattern="[A-Za-z]\w+"
              placeholder="Please enter your last name"
              v-decorator="[
                'last_name',
                {
                  rules: [
                    { required: true, message: 'Last Name is required!' },
                  ],
                },
              ]"
            />
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <a-form-item label="Country">
            <a-select
              ref="country"
              @change="handleCountrySelect"
              show-search
              :filter-option="filterOptions"
              option-filter-prop="country"
              v-decorator="[
                'address.country',
                {
                  rules: [{ required: true, message: 'Country is required!' }],
                },
              ]"
              placeholder="Please select your country"
            >
              <a-select-option
                :key="index"
                v-for="(countryObject, index) in countries"
                :value="countryObject.geo_name_id"
              >
                {{ countryObject.country }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <a-form-item>
            <a-button @click="handleSubmit" type="primary" html-type="submit">
              Next
            </a-button>
          </a-form-item>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Persist from "@/mixins/Persist";

import { MISC_GET_GEO_DATA } from "@/store/actions";
import { validateId } from "@/helpers/index.js";
import { cloneDeep } from "lodash";

export default {
  components: {},
  mixins: [Persist],
  data() {
    return {
      form: this.$form.createForm(this, { name: "basicinformation" }),
      country: {},
    };
  },
  computed: {
    ...mapGetters("misc", ["getCountriesList"]),
    basicInformationFromStore() {
      return this.$getFromLocal("basicInformation");
    },
    countries() {
      return this.getCountriesList;
    },
  },
  methods: {
    validateId,
    setSelectedId(str) {
      this.selectedIdType = str;
    },
    ...mapActions("misc", {
      getGeoData: MISC_GET_GEO_DATA,
    }),
    coerceAddressValue(countryStateCity) {
      try {
        const countryStructure = (address) => {
          return {
            geo_id: address.geo_name_id,
            latitude: address.latitude,
            longitude: address.longitude,
            name: address.country,
            iso_code: address.iso_code,
          };
        };

        if (countryStateCity.country) {
          const data = this.getCountriesList.find(
            (country) => countryStateCity.country == country.geo_name_id
          );
          countryStateCity.country = countryStructure(data);
        }
        return countryStateCity;
      } catch (e) {
        console.log(e);
      }
    },
    filterOptions(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          const modifiedValues = cloneDeep(values);

          const { address } = modifiedValues;
          modifiedValues.address = this.coerceAddressValue(address);

          const data = { user: modifiedValues };
          this.$emit("next", data);

          this.$storeInLocal("basicInformation", values);
        }
      });
    },
    async handleCountrySelect(selected) {
      this.country = this.countries.find(
        (country) => country.geo_name_id === selected
      );
    },
  },
  mounted() {
    if (this.basicInformationFromStore) {
      this.$nextTick().then(() => {
        this.form.setFieldsValue(this.basicInformationFromStore);
      });
    }
  },
};
</script>
